import React, { useState, useEffect } from 'react';

import { mainVariables } from '../../../../../hooks/mainVariables';

import classes from './EditSite.module.css';

import Button from '../../../../../utils/Button/Button';

import { useForm } from '../../../../../hooks/form-hook';
import { siteFormInputs } from './EditSite_form';

import Photocamera from '../../../../../utils/Photocamera';

function EditSite({ action, site, setSite, clear }) {
	/**
	 * ---------------------------------------
	 * Gestione inputs
	 * --------------------------------------
	 */

	const checkedHandler = id => {
		let _val = Boolean(document.getElementById(id).checked);
		inputHandler(id, _val, true);
		formState.inputs[id].value = _val;
		console.log({ _val });
	};

	const [formState, inputHandler, setFormData, setInputs] = useForm(
		siteFormInputs(site, checkedHandler)
	);
	const [inputsVisuals, setInputsVisuals] = useState(setInputs(formState));

	const evalButtonDisabled = () => {
		let disabled = !formState.isValid;

		return disabled;
	};

	// < Gestione inputs --------------------------------

	/**
	 * -----------------------------------------
	 * Immagine di copertina
	 * -----------------------------------------
	 */
	const [imageUrl, setImageUrl] = useState(site.coverImg || null);

	// < Immagine di copertina -----------------

	const post = () => {
		console.log('Posto');

		if (imageUrl) {
			site.coverImg = imageUrl;
		}

		const i = formState.inputs;
		const keys = Object.keys(i);

		const uSite = {};

		keys.map(k => {
			if (i[k] !== site?.[k]) {
				uSite[k] = i[k].value;
			}
		});

		setSite(pSite => {
			return { ...pSite, ...uSite };
		});
		closeForm();
	};

	const closeForm = () => {
		clear();
	};

	return (
		<React.Fragment>
			<div className={classes.background} onClick={closeForm} />
			<div className={classes.wrapper}>
				{site?.name}
				<div className={classes.formWrapper}>{inputsVisuals}</div>
				{site?.imgFolderName && (
					<Photocamera
						setImageUrl={setImageUrl}
						saveUrl={'site/saveCover'}
						folder={site?.imgFolderName}
						otherData={{ id: site._id, fixedName: 'Cover' }}
					/>
				)}
				{imageUrl && (
					<img
						className={classes.siteCover}
						id='imagePreview'
						alt="Preview dell'immagine"
						src={`${mainVariables.srv}images/${imageUrl}`}
						style={{ maxWidth: '100%', height: 'auto', marginTop: '10px' }}
					/>
				)}
				<div className={classes.buttons}>
					<Button clname='danger big' action={closeForm}>
						Chiudi
					</Button>
					<Button
						clname='confirm big'
						disabled={evalButtonDisabled()}
						action={post}
					>
						Salva
					</Button>
				</div>
			</div>
		</React.Fragment>
	);
}

export default EditSite;
