import React, { useContext, useState, useRef, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ReactDom from 'react-dom';

import classes from './ExctSafety.module.css';

import { ymdFormDate } from '../../../../../../lib/functrions';

import { ReportCxt } from '../../../../../../context/reportCxt';
import { useHttpClient } from '../../../../../../hooks/http-hooks';
import LoadingSpinner from '../../../../../../utils/LoadingSpinner';
import ErrorModal from '../../../../../../utils/ErrorModal';

import IconButton from '../../../../../../utils/IconButton';

import SiteMainData from '../../_parts/SiteMainData/SiteMainData';
import ContactSection from '../../_parts/ContactSection/ContactSection';
import ActiveWorks from './ActiveWorks/ActiveWorks';
import FinalProvisions from '../../_parts/FinalProvisions/FinalProvisions';
import ReportSignatures from '../../_parts/ReportSignatures/ReportSignatures';

import RegOpenLine from '../../../../Comps/RegOpenLine';
import CloseRecord from './ActiveWorks/CloseRecord/CloseRecord';
import SolvedReportLine from '../../_parts/SolvedReportLine/SolvedReportLine';

function ExctSafety() {
	const { reportState, ...rActions } = useContext(ReportCxt);
	const { clearError, error, isLoading, sendRequest } = useHttpClient();
	const goTo = useNavigate();
	const p = useParams();

	const refEditDate = useRef(null);
	const refEditTime = useRef(null);

	let r_creationDate = new Date();

	if (reportState?.reportHeader?.creationDate) {
		r_creationDate = new Date(reportState.reportHeader.creationDate);
	}

	if (!reportState.site) {
		goTo('/Cantieri/' + p.site);
	}

	/**> Modifica data---------------- */
	const [editDate, setEditDate] = useState(false);
	const handleEditDate = () => {
		setEditDate(!editDate);
	};
	const changeDate = async () => {
		const nDate = refEditDate.current.value;
		if (nDate) {
			const splitDate = nDate.split('-');

			r_creationDate.setFullYear(Number(splitDate[0]));
			r_creationDate.setMonth(Number(splitDate[1] - 1));
			r_creationDate.setDate(Number(splitDate[2]));

			rActions?.updateReportState(
				'reportHeader',
				'creationDate',
				r_creationDate
			);

			await sendRequest(
				'report/edit',
				'POST',
				{
					rId: reportState.reportHeader._id,
					par: 'creationDate',
					value: r_creationDate,
				},
				{ 'Content-Type': 'application/json' }
			);

			handleEditDate();
		}
	};
	const showEditDate = () => {
		return (
			<div className={classes.editForm}>
				<input
					type='date'
					id='edit_creationDate'
					name='edit_creationDate'
					ref={refEditDate}
				/>
				<IconButton
					text={'close'}
					action={handleEditDate}
					style={{ fontSize: '1.5rem', color: 'var(--STOPED)' }}
				/>
				<IconButton
					text={'check_circle'}
					action={changeDate}
					style={{ fontSize: '1.5rem', color: 'var(--ONGOING)' }}
				/>
			</div>
		);
	};
	/**< ------------------------------- */

	const handleChangedInputValue = () => {
		// console.log('cambiato');
	};

	/** -------------------------------- */
	/**> Modifica Orario---------------- */
	/** -------------------------------- */
	const [editTime, setEditTime] = useState(false);
	const handleEditTime = () => {
		setEditTime(!editTime);
	};

	const changeTime = async () => {
		const nTime = refEditTime.current.value;
		if (nTime) {
			const splitTime = nTime.split(':');
			// console.log(splitTime);

			r_creationDate.setHours(Number(splitTime[0]));
			r_creationDate.setMinutes(Number(splitTime[1]));

			rActions?.updateReportState(
				'reportHeader',
				'creationDate',
				r_creationDate
			);

			await sendRequest(
				'report/edit',
				'POST',
				{
					rId: reportState.reportHeader._id,
					par: 'creationDate',
					value: r_creationDate,
				},
				{ 'Content-Type': 'application/json' }
			);

			handleEditTime();
		}
	};

	const showEditTime = () => {
		return (
			<div className={classes.editForm}>
				<input
					type='time'
					id='edit_creationTime'
					name='edit_creationTime'
					ref={refEditTime}
					onChange={handleChangedInputValue}
				/>
				<IconButton
					text={'close'}
					action={handleEditTime}
					style={{ fontSize: '1.5rem', color: 'var(--STOPED)' }}
				/>
				<IconButton
					text={'check_circle'}
					action={changeTime}
					style={{ fontSize: '1.5rem', color: 'var(--ONGOING)' }}
				/>
			</div>
		);
	};
	/**<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */
	/**<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */

	/** -------------------------------- */
	/**> Gestione Presenti ------------- */
	/** -------------------------------- */

	const [triggerEditPartecipants, setTriggerEditPartecipants] = useState(false);

	const updatePartecipants = (action, p) => {
		// console.log({ action });
		// console.log({ p });
		setTriggerEditPartecipants(true);
		const partecipants = reportState?.reportHeader?.refPartecipants || [];
		const newList = [...partecipants];

		let updateParameter = '';

		switch (action) {
			case 'add':
				newList.push(p);
				updateParameter = 'refPartecipants';
				break;
			case 'remove':
				let index = null;
				newList.map((el, i) => {
					if (el.refContatto._id === p.refContatto._id) {
						// console.log(el.refContatto);
						index = i;
					}
				});

				// console.log({ index });

				if (index >= 0) {
					newList.splice(index, 1);
				}
				updateParameter = 'refPartecipants';

				break;
			// case 'insert':
			// 	let idx_guest = null;
			// 	// newList.map((el, i) => {
			// 	// 	if (el.refContatto._id == p.refContatto._id) {
			// 	// 		idx_guest = i;
			// 	// 	}
			// 	// });

			// 	// console.log({ idx_guest });
			// 	// console.log({ partecipants });

			// 	if (!idx_guest) {
			// 		newList.push({
			// 			refContatto: {
			// 				email: 'inserire mail',
			// 				fullname: 'Ospite 1',
			// 				jobTitle: 'Ing.',
			// 				name: 'Sandro',
			// 				phoneNumber: '0461 922028',
			// 				refCompany: '',
			// 				surname: 'Tagliaferri',
			// 				refCompany: 'guest',
			// 				_id: Date.now(),
			// 			},
			// 			refSiteRole: {
			// 				name: 'Ruolo di prova',
			// 				_id: Date.now(),
			// 			},
			// 		});
			// 	}
			// updateParameter = 'guests'

			// 	break;

			default:
				// // setTriggerEditPartecipants(false);
				break;
		}

		editPartecipants(newList);
	};

	const editPartecipants = async list => {
		// console.log({ list });
		const updatedHeader = await sendRequest(
			'report/edit',
			'POST',
			{
				rId: reportState.reportHeader._id,
				par: 'refPartecipants',
				value: list,
			},
			{ 'Content-Type': 'application/json' }
		);

		// console.log({ updatedHeader });

		rActions.updateReportHeader(updatedHeader);
	};

	/**<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */

	/** -------------------------------- */
	/**> Gestione Report Line ---------- */
	/** -------------------------------- */

	const deleteReportLine = async id => {
		const deletedLine = await sendRequest(
			'report/line/delete',
			'POST',
			{
				id: id,
			},
			{ 'Content-Type': 'application/json' }
		);

		// console.log(deletedLine);
		rActions.removeReportLine(id);
	};

	/**<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */

	/** -------------------------------- */
	/**> Gestione Report Line ---------- */
	/** -------------------------------- */

	const updateFinalProvisioning = async (id, value) => {
		const r = await sendRequest(
			'report/edit',
			'POST',
			{
				rId: id,
				par: 'finalProvisions',
				value: value,
			},
			{ 'Content-Type': 'application/json' }
		);

		return r;
	};

	/**<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */

	/** --------------------------------------- */
	/**> Gestione Stampa e archiviazionex ----- */
	/** --------------------------------------- */

	const oldPageTitle = useRef(document.title);

	const printReport = () => {
		window.print();
	};

	const changePageTitle = () => {
		let reportDate = new Date(reportState.reportHeader.creationDate);

		let pTitle = reportState.reportHeader.imgFolder.split('/')[0];
		pTitle += ' - ';

		pTitle += ymdFormDate(reportDate, ' ');

		document.title = pTitle;

		setTimeout(() => {
			document.title = oldPageTitle.current;
		}, 1000);
	};

	window.addEventListener('beforeprint', changePageTitle);

	const archiveReport = async () => {
		const b = window.confirm(
			`Una volta archiviato il report non potrà più essere modificato e le immagini verranno eliminate (firme comprese) \n\nProseguire? `
		);

		if (b) {
			// console.log('Invio richiesta archiviazione');

			const r = await sendRequest(
				'report/archive',
				'POST',
				{ id: reportState.reportHeader._id },
				{ 'Content-Type': 'application/json' }
			);

			await rActions?.updateReportState('reportHeader', 'isClosed', r.isClosed);
		}
	};
	/**<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */

	/** --------------------------------------- */
	/**> Gestione chiusura prescrizione ----- */
	/** --------------------------------------- */
	const [selectedLine, setSelectedLine] = useState(null);

	const closeOldLine = line => {
		setSelectedLine(line);
	};

	const showCloseForm = () => {
		const form = (
			<CloseRecord
				data={selectedLine}
				closeRecord={postClosedLine}
				clear={() => setSelectedLine(null)}
			/>
		);

		return ReactDom.createPortal(form, document.getElementById('modal-hook'));
	};

	const postClosedLine = async line => {
		// console.log(line);

		const uLine = {
			refResolutionReportHeader: reportState.reportHeader._id,
			resolution: line.resolution,
			resolutionDate: line.resolutionDate,
			isClosed: true,
		};

		const uLines = await sendRequest(
			'report/line/close',
			'POST',
			{
				lineData: uLine,
				id: line._id,
			},
			{ 'Content-Type': 'application/json' }
		);

		const uRepHeader = { ...reportState.reportHeader };
		uRepHeader.closedLines = uLines;
		rActions.updateReportHeader(uRepHeader);

		const uSite = { ...reportState.site };

		const uOpenActivities = reportState.site.openActivities.filter(a => {
			return a._id !== line._id;
		});

		uSite.openActivities = [...uOpenActivities];

		rActions.addState(uSite);
		setSelectedLine(null);
	};

	const evalOpenActivities = () => {
		const lineVisuals = [];
		reportState?.site?.openActivities.map(l => {
			if (
				l.refReportHeader.isClosed &&
				l.refReportType === reportState?.reportHeader?.reportModel
			) {
				let alreadyClosed = false;
				if (reportState?.reportHeader?.closedLines?.length > 0) {
					reportState?.reportHeader?.closedLines.map(c => {
						if (c._id == l._id) {
							alreadyClosed = true;
						}
					});
				}

				if (alreadyClosed) {
					return;
				}

				lineVisuals.push(
					<RegOpenLine
						key={l._id}
						line={l}
						action={closeOldLine}
						isReport={true}
					/>
				);
			}
		});

		return lineVisuals;
	};

	const CL_sovled = useRef(classes.noPrint);

	const evalClosedActivities = () => {
		if (!reportState?.reportHeader?.closedLines?.length) {
			return;
		}

		const lineVisuals = [];

		let companyGroup = [];

		let lastCompany = reportState?.reportHeader?.closedLines[0]?.refCompany;

		reportState?.reportHeader?.closedLines?.map(l => {
			if (l?.refCompany?._id !== lastCompany._id) {
				lastCompany = l.refCompany;
				lineVisuals.push(
					<div key={lastCompany._id}>
						<div className={classes.header}>{l.refCompany.fullname}</div>
						<div className={classes.lines}>{companyGroup}</div>
					</div>
				);

				companyGroup = [];
			}

			companyGroup.push(<SolvedReportLine key={l._id} line={l} />);
		});

		lineVisuals.push(
			<div key={lastCompany._id}>
				<div
					className={classes.solvedHeader}
					style={{ fontSize: '1.5em', fontWeight: '500' }}
				>
					{lastCompany.fullname}
				</div>
				<div className={classes.solvedLines}>{companyGroup}</div>
			</div>
		);

		if (lineVisuals.length > 0) {
			CL_sovled.current = classes.solved;
		}
		return lineVisuals;
	};
	/**<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */

	return (
		<React.Fragment>
			{error && <ErrorModal error={error} onClear={clearError} />}
			{isLoading && <LoadingSpinner asOverlay />}
			{selectedLine && showCloseForm()}
			<div className={classes.wrapper}>
				<div className={`${classes.headerDescription} ${classes.boxed}`}>
					<div className={classes.hLogo}>
						<img src={require('../../../../../../assets/eng_logo_small.png')} />
					</div>
					<div className={classes.hTitle}>
						<span style={{ fontSize: '1.5em', fontWeight: '400' }}>
							{reportState?.site?.name}
						</span>
						<div className={classes.date}>
							Verbale del{' '}
							{editDate ? (
								showEditDate()
							) : (
								<span className={classes.accent} onClick={handleEditDate}>
									{r_creationDate.toLocaleDateString('it-IT', {
										month: '2-digit',
										day: '2-digit',
										year: 'numeric',
									})}
								</span>
							)}{' '}
							alle ore{' '}
							{editTime ? (
								showEditTime()
							) : (
								<span className={classes.accent} onClick={handleEditTime}>
									{r_creationDate.toLocaleTimeString('it-IT', {
										hour: '2-digit',
										minute: '2-digit',
										hour12: false,
									})}
								</span>
							)}
						</div>
						<span className={classes.header}>Sicurezza in esecuzione</span>
					</div>
					<div className={classes.operator}>
						<p>C.S.E</p>
						<p className={classes.accent}>Ing. Sandro Tagliaferri</p>
						<div>
							<p>c/o ENG GROUP</p>
							<p>Via Innsbruck n.15 - 38121 Trento</p>
						</div>
					</div>
				</div>

				{/* > - Descrizione cantiere */}
				<div className={`${classes.boxed} ${classes.commessa}`}>
					<p className={classes.title}>COMMESSA:</p>
					{reportState?.site?.descriptionBig}
				</div>

				{/* > - Informazioni cantiere */}
				<div className={`${classes.boxed} ${classes.SiteMainData}`}>
					<p className={classes.title}>Informazioni cantiere:</p>
					<SiteMainData />
				</div>
				{/* < */}

				{/* > - Presenti alla riunione */}
				<div className={`${classes.boxed} ${classes.presence}`}>
					<div className={classes.title}>Presenti al sopralluogo</div>
					<ContactSection
						contactPerson={reportState?.site?.contactPerson}
						contacts={reportState?.site?.contacts}
						updatePartecipants={updatePartecipants}
						committente={reportState?.site?.committente}
						// filter={['signatureRequired', true]}
					/>
				</div>
				{/* <  */}

				{/* > - Riassunto prescrizione attive */}
				<div className={`${classes.boxed} ${classes.suspended}`}>
					<div className={classes.title}>Prescrizioni da verificare</div>
					{evalOpenActivities()}
				</div>
				<div
					className={`${classes.boxed} ${classes.solved} ${CL_sovled.current}`}
				>
					<div className={classes.title}>Prescrizioni risolte</div>
					{evalClosedActivities()}
				</div>

				{/* < */}

				{/* > - Andamento e coordinamento lavorazioni */}
				<div className={`${classes.boxed} ${classes.activeWorks}`}>
					<div className={classes.title} title=''>
						Andamento e coordinamento lavorazioni
					</div>
					<ActiveWorks deleteReportLine={deleteReportLine} />
				</div>
				{/* <  */}

				{/* > - Disposizioni finali */}
				<div
					className={`${classes.boxed} ${classes.finalProvisions} ${
						reportState?.reportHeader?.finalProvisions == ''
							? classes.noPrint
							: ''
					}`}
				>
					<div className={classes.title} title=''>
						Disposizioni finali
					</div>

					<FinalProvisions update={updateFinalProvisioning} />
				</div>
				{/* <  */}

				{/* > - Firme */}
				{reportState.reportHeader?.refPartecipants && (
					<div className={`${classes.boxed} ${classes.signatures}`}>
						<div className={classes.title} title=''>
							Firme
						</div>
						<ReportSignatures />
					</div>
				)}
				{/* <  */}

				<div className={`${classes.buttons} ${classes.noPrint}`}>
					{!reportState?.reportHeader?.isClosed && (
						<IconButton text={'archive'} action={archiveReport} />
					)}
					<IconButton text={'print'} action={printReport} />
				</div>
				{/* <div className={classes.footer}>
					Riga di informazioni footer del verbale, con posizione assoluta
				</div> */}
			</div>
		</React.Fragment>
	);
}

export default ExctSafety;
